/* Common Side Padding
54px = Desktop
*/
/* Common Side Padding
12px = Mobile
*/
.primarySvg {
  fill: black;
  width: 20px;
  height: 20px;
}
.freeColor {
  fill: #1b6600;
  width: 2rem;
  height: 2rem;
}
.bottomMenuSvg {
  fill: black;
  width: 20px;
  height: 20px;
}
.animation-text {
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
.animation-display {
  transition: display 3s;
  -webkit-transition: display 3s;
}
.animation-transform {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.animation-border {
  transition: border 0.3s;
  -webkit-transition: border 0.5s;
}
.header-submenucontent {
  display: none;
  width: 100%;
  position: fixed;
  opacity: 0;
  left: 0;
  background-color: #fafafa;
  animation: scale-display--reversed 0.3s;
  animation-fill-mode: forwards;
}
.headerContentDesktop {
  position: fixed;
  display: block;
}
.headerContentMobile {
  display: none;
  position: fixed;
}
.header-menu-item {
  position: relative;
}
.header-menu-item:hover .header-submenucontent {
  display: block;
  opacity: 1;
  animation: scale-display 0.3s;
}
.header-menu-item:hover .border-active {
  height: 2px;
  display: block;
  position: absolute;
  z-index: 1;
  bottom: -10px;
  left: 0;
  width: 100%;
  animation: scale-display 0.3s;
}
.border-active {
  display: none;
  animation: scale-display--reversed 0.3s;
  animation-fill-mode: forwards;
}
.asdasd {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 0% !important;
}
.bottom-navbar {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 5px;
  height: 60px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(32 32 36 / 13%) 0px -2px 4px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.bottom-customization {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 5px;
  height: 45px;
  z-index: 1;
  background-color: #202020;
  box-shadow: rgba(255, 255, 255, 0.13) 0px -2px 4px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.selected-menu-border {
  background: rgb(247 192 36);
  width: 70%;
  height: 3px;
  border-bottom-right-radius: 15px 15px;
  border-bottom-left-radius: 15px 15px;
}
.menu-border {
  width: 70%;
  height: 3px;
}
.footer-social-link-icon {
  border: 1px solid #4b4b4b;
}
.footer-social-link:hover .footer-social-link-icon {
  border: 1px solid white;
}
.bottom-menu-items {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
}
.bottom-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hide-scrollBar::-webkit-scrollbar {
  /* display: none; */
  background-color: #4b4b4b;
  cursor: pointer;
  height: 8px;
  border-radius: 10px;
}
.hide-scrollBar::-webkit-scrollbar-thumb {
  background: #656565;
  border-radius: 10px;
}
.hide-scrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: #2e2e2e;
}
.hide-scrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.remove-scrollbar::-webkit-scrollbar {
  display: none;
}
.remove-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.fullhide-scrollBar::-webkit-scrollbar {
  /* display: none; */
  background-color: #6d6d6d;
  cursor: pointer;
  height: 10px;
  border-radius: 10px;
}
.fullhide-scrollBar::-webkit-scrollbar-thumb {
  background: rgb(83, 83, 83);
  border-radius: 10px;
}
.fullhide-scrollBar::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0);
}
.fullhide-scrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  padding: 4px 0;
  border-radius: 10px;
  background: #e4e4e4;
}
.fullhide-scrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sub-menu-footer {
  cursor: pointer;
  margin-top: 5px;
  border: 1px solid black;
}
.sub-menu-footer:hover {
  border: 1px solid white;
}
.desktop-footer-layout {
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.footer-menu-layout {
  grid-column: span 2 / span 2;
}
.mobile-footer-layout {
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.desktop-imageslider {
  width: 100%;
}
.desktop-slider-layout {
  width: 100%;
}
.desktop-imageslider-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.desktop-slider-Box {
  width: 100%;
  height: auto;
  cursor: pointer;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.desktop-slider-image {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.desktop-slider-gutter {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.desktop-product-slider-main {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}
.desktop-product-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.desktop-slider-static {
  width: 100%;
}
.desktop-grid-image {
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.zoom-image-parent {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
}
.desktop-grid-gutter {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.desktop-slider-text {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  bottom: 10%;
  background-color: rgb(0, 0, 0, 0.4);
  padding: 10px 0;
  text-align: center;
}
.mobile-slider-text {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  bottom: 10%;
  background-color: rgb(0, 0, 0, 0.4);
  padding: 6px 0;
  text-align: center;
}
.desktop-slider-Box:hover .desktop-zoom-image {
  transform: scale(1.1);
}
.desktop-grid-image:hover .desktop-zoom-image {
  transform: scale(1.1);
}
.zoom-image-parent:hover .desktop-zoom-image {
  transform: scale(1.1);
}
.desktop-zoom-image {
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    transform 0.5s ease-out 0s, -webkit-transform 0.5s ease-out 0s;
  display: block;
}
.main-children {
  padding: 0px 54px 10px;
}
.mobile-imageslider-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-imageslider-slick {
  width: 100%;
}
.mobile-imageslider {
  width: 100%;
}
.mobile-slider-images-group {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  scroll-behavior: smooth;
  padding-bottom: 1rem;
  gap: 10px;
  overflow-x: auto;
}
.mobile-slider-layout {
  width: 100%;
}
.mobile-slick-slider {
  width: 100%;
}
.mmmm {
  position: relative;
}
.mobile-slider-image {
  height: auto;
  display: block;
  width: 100%;
}
.desktop-imagegrid {
  display: grid;
}
.mobile-imagegrid {
  display: grid;
}

.common-space-section-top {
  margin-top: 20px;
}
.common-gap-section-top {
  gap: 10px;
}
.common-space-section-bottom {
  margin-bottom: 20px;
}
.common-border-radius {
  border-radius: 10px;
}
.common-space-content-top {
  gap: 20px;
}
.common-gap-content-top {
  margin-top: 20px;
}
.common-space-content-bottom {
  margin-bottom: 20px;
}
.product-image {
  display: block;
  height: auto;
  border-radius: 8px;
  width: 100%;
  /* opacity: 0; */
  -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    -webkit-transform 0.5s ease-out 0s;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    -webkit-transform 0.5s ease-out 0s;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    transform 0.5s ease-out 0s;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    transform 0.5s ease-out 0s, -webkit-transform 0.5s ease-out 0s;
  z-index: 1;
  position: relative;
}

.filter-sort-menu .MuiPaper-root {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.filter-menu .MuiPaper-root {
  height: 100%;
  overflow: hidden;
}
.mobile-product-category {
  display: grid;
}
.mobile-product-category-container {
  display: block;
}
.desktop-product-category {
  display: grid;
}
.cart-product-image {
  width: 7rem;
  height: 9rem;
  max-width: 7rem;
  max-height: 9rem;
  display: block;
  border-radius: 6px;
}
.cart-wishlist-image {
  width: 5rem;
  height: 6rem;
  display: block;
  object-fit: contain;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(32, 32, 32, 0.16);
  border-radius: 6px;
}
.downarrow-icon {
  fill: black;
  width: 12px;
  height: 10px;
}
.remove-cart-image {
  width: 5rem;
  border-radius: 10px;
  display: block;
  height: auto;
}
.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.login-phone-input .form-control {
  width: 100% !important;
  border: 1px solid black !important;
  border-radius: 14px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.login-phone-input .form-control:focus {
  box-shadow: 0 0 0 1px black !important;
}
.purple-input-phone .form-control {
  width: 100% !important;
  border: 2px solid #333 !important;
  border-radius: 14px !important;
  color: #333 !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.purple-input-phone .form-control:focus {
  box-shadow: 0 0 0 1px #333 !important;
}
.purple-input-phone .special-label {
  color: #333 !important;
  font-weight: 600 !important;
}
/* .mui-input-field .MuiInputBase-input {
  width: 100% !important;
  border: 1px solid red !important;
  border-radius: 14px !important;
} */
.search-input-bar .MuiInputBase-input {
  padding: 0.75rem 0;
}
.loginform-field-icons {
  width: 1.2rem;
  height: 1.2rem;
  fill: rgb(52, 52, 52);
}
.input-field-main .MuiOutlinedInput-notchedOutline {
  border: 2px solid #d2d2d2 !important;
  border-radius: 14px !important;
}
.input-field-main .MuiOutlinedInput-notchedOutline legend span {
  display: none;
}
.input-field-main .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #9f9f9f !important;
  border-radius: 14px !important;
}
.input-field-main .MuiInputLabel-outlined {
  color: rgb(52, 52, 52) !important;
  background-color: white;
  padding: 0px 4px;
}
.input-field-main .MuiOutlinedInput-input {
  color: rgb(52, 52, 52) !important;
  font-weight: 600 !important;
}
.input-field-main .MuiOutlinedInput-input::placeholder {
  color: rgb(52, 52, 52) !important;
}

.purple-field-icons {
  width: 1.2rem;
  height: 1.2rem;
  fill: #333;
}
.purple-input-field .MuiOutlinedInput-notchedOutline {
  border: 2px solid #333 !important;
  border-radius: 14px !important;
}
.purple-input-field .MuiOutlinedInput-notchedOutline legend span {
  display: none;
}
.purple-input-field .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #333 !important;
  border-radius: 14px !important;
}
.purple-input-field .MuiInputLabel-outlined {
  color: #333 !important;
  background-color: white;
  padding: 0px 4px;
  font-weight: 600 !important;
}
.purple-input-field .MuiOutlinedInput-input {
  color: #333 !important;
  font-weight: 600 !important;
}
.purple-input-field .MuiOutlinedInput-input::placeholder {
  color: #333 !important;
}

.dialog-border-radius-mui .MuiDialog-paper {
  border-radius: 15px !important;
}
.radio-button-input {
  margin: 0;
  width: 18px;
  cursor: pointer;
  height: 18px;
  accent-color: black;
}
.loader-shimmer {
  -webkit-animation: placeHolderShimmer 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite forwards;
  animation: placeHolderShimmer 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    forwards;
  background: #eaeaec -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0, #eaeaec),
      color-stop(35%, #dad9d9),
      color-stop(70%, #eaeaec),
      to(#eaeaec)
    ) no-repeat;
  background-size: 800px 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.confirmation-gif {
  width: 7rem;
}
.stepper-label {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.stepper-label-group {
  grid-column: span 1 / span 1;
}
.stepper-alignitems-center {
  text-align: center;
}
.stepper-line {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  position: relative;
  /* width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #6d6d6d; */
}
.step-line {
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #bebebe;
}
.stepper-round {
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: calc(-0.6rem + 2px);
  background-color: #bebebe;
  border-radius: 50%;
}
.stepper-icon {
  position: absolute;
  height: 100%;
  top: calc(-1.5rem + 2px);
}
.stepppp-line {
  min-height: 3rem;
  width: 4px;
  margin-top: -3rem;
  margin-left: 0.5rem;
  border-radius: 8px;
}
.imagelist-adjust-mob {
  padding: 1rem !important;
  column-gap: 2rem !important;
  height: 100% !important;
}
.about-info {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 30px 40px;
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-top: 50px !important;
}
.accordion-group {
  width: 100%;
}
.accordion-item {
  background-color: #f0f4fd;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  cursor: pointer;
}
.accordion-title {
  display: flex;
  font-weight: 600;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-between;
}
.accordion-questionmark {
  margin-right: 10px;
}
.account-menu-down-icon {
  width: 15px;
  margin-left: 40px;
  height: 15px;
  fill: white;
  /* transform: rotate(180deg); */
}
.arrow-icon-faq {
  fill: black !important;
}
.accordion-content {
  padding: 10px 15px;
  margin-left: 15px;
}
.line-height-content {
  line-height: 25px !important;
  text-align: justify;
}
.image-container-category-product {
  transition: opacity 0.5s ease-in-out;
}
.react-tel-input :disabled {
  cursor: not-allowed;
  border: 0.5px solid #9d9d9d !important;
  color: #9d9d9d !important;
}
/* SEARCH RESULTS */
#autoCompleteDiv .autocomplete {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  left: 0;
  z-index: 10;
  top: 76px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 5px 24px -15px black;
  transition: all 0.5s;
}

#autoCompleteDiv .autocomplete .autocomplete_inner {
  color: black;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 32px;
  padding-right: 5px;
  font-weight: bold;
}

#autoCompleteDiv .autocomplete .autocomplete_inner:first-child {
  padding-top: 10px;
}

#autoCompleteDiv .autocomplete .autocomplete_inner:last-child {
  border-bottom: 0px solid #ddd;
}

#autoCompleteDiv .autocomplete .autocomplete_inner:hover {
  /* background-color: red; */
  cursor: pointer;
  background-color: #ebebeb;
  color: #000;
}
#autoCompleteDiv .autocomplete .autocomplete_inner.active {
  /* background-color: red; */
  cursor: pointer;
  background-color: #ebebeb;
  color: #000;
}
/* SEARCH RESULTS */
@media only screen and (min-width: 1024px) {
  .headerContentDesktop {
    display: block;
  }
  .headerContentMobile {
    display: none;
  }
  .bottom-navbar {
    display: none;
  }
  .bottom-customization {
    display: none;
  }
  .mobile-footer-layout {
    display: none;
  }
  .mobile-slider-layout {
    display: none;
  }
  .mobile-imageslider {
    display: none;
  }
  .mobile-imageslider-slick {
    display: none;
  }
  .mobile-slick-slider {
    display: none;
  }
  .mobile-slider-text {
    display: none;
  }
  .mobile-imagegrid {
    display: none;
  }
  .mobile-imagestatic {
    display: none;
  }
  .mobile-product-category {
    display: none;
  }
  .mobile-product-category-container {
    display: none;
  }
  .mobile-product-page {
    display: none;
  }
  .mobile-view-cart-page {
    display: none;
  }
  .cart-product-image {
    width: 7rem;
    height: 9rem;
    max-width: 7rem;
    max-height: 9rem;
  }
  .remove-cart-image {
    width: 7rem;
    height: auto;
  }
  .mobile-checkout-page {
    display: none;
  }
  .mobile-wishlist-page {
    display: none;
  }
  .mobile-account-page {
    display: none;
  }
}
@media only screen and (min-width: 280px) and (max-width: 1023px) {
  .main-children {
    padding: 0px 12px 10px;
  }
  .headerContentDesktop {
    display: none;
  }
  .headerContentMobile {
    display: block;
  }
  .desktop-footer-layout {
    display: none;
  }
  .desktop-slider-layout {
    display: none;
  }
  .desktop-imageslider {
    display: none;
  }
  .desktop-imagegrid {
    display: none;
  }
  .desktop-imagestatic {
    display: none;
  }
  .desktop-product-category {
    display: none;
  }
  .desktop-product-page {
    display: none;
  }
  .desktop-view-cart-page {
    display: none;
  }
  .desktop-checkout-page {
    display: none;
  }
  .desktop-wishlist-page {
    display: none;
  }
  .desktop-account-page {
    display: none;
  }
  .accordion-item {
    margin-left: 0;
    margin-right: 0;
  }
  .about-info {
    margin-top: 20px !important;
    padding: 20px 5px;
    width: auto;
  }
  .cart-product-image {
    max-width: 6rem;
    max-height: 9rem;
  }
}

@keyframes scale-display {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-display--reversed {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}
.shake-the-div {
  animation: shake-component 0.3s 1;
  -webkit-animation: shake-component 0.3s 1;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
@keyframes shake-component {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
  .footer-logo {
    width: 200px;
    height: 40px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 749px) {
  .footer-logo {
    width: 220px;
    height: 40px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1023px) {
  .footer-logo {
    width: 220px;
    height: 40px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1255px) {
  .footer-logo {
    width: 150px;
    height: 30px;
  }
}

@media only screen and (min-width: 1256px) and (max-width: 1440px) {
  .footer-logo {
    width: 180px;
    height: 30px;
  }
}

@media only screen and (min-width: 1441px) {
  .footer-logo {
    width: 200px;
    height: 30px;
  }
}
